

















































import Vue from 'vue'

interface para {
    type: string;
    content: string;
}

let api = "blog/"

export default Vue.extend({
    data() {
        return {
            title: "",
            tags: [] as string[],
            paragraphs: [] as para[],
            contentTypeList: [
                "paragraph",
                "image",
            ],
            // 文章 id
            pageId: 0,
        }
    },
    mounted() {
        // 得到文章 id
        this.pageId = Number(this.$route.query.id)

        // 获取页面数据
        this.getPage()
    },
    methods: {
        /* 获取页面数据 */
        getPage()
        {
            console.log(this.pageId)
            this.$http.get(api + "page/", {
                params: {
                    page: this.pageId,
                }
            }).then(res=>{
                console.log(res)
                if (res.status == 200)
                {
                    let data = res.data
                    this.title = data.title
                    this.tags = data.tags
                    this.paragraphs = data.paragraphs
                } else {
                    this.title = "找不到文章！"
                    this.tags = []
                    this.paragraphs = [
                        { type: "paragraph", content: "很抱歉，找不到该文章" },
                    ]
                }
            }).catch(err=>{
                console.warn(err)
            })
        },
    },
})
