































































import {
    Button,
    Input,
    Message,
    // skeleton 和 skeleton-item 这两个必须要一起引用
    Popconfirm,
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Popconfirm)

let api = "/blog/page/"

interface Paragraph {
    type: string,
    content: string,
}

import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            title: "",
            coverImg: "",
            overview: "",
            tags: [] as string[],
            paragraphs: [""] as string[],
            taginput: "",
        }
    },
    methods: {
        addTag() {
            if (!this.tags.includes(this.taginput)) {
                this.tags.push(this.taginput)
            }
            this.taginput = ""
        },
        delTag(index) {
            this.tags.splice(index, 1)
        },
        addPara() {
            this.paragraphs.push("")
        },
        delPara(index) {
            this.paragraphs.splice(index, 1)
        },
        generateUploadPara() {
            let data = [] as Paragraph[]
            this.paragraphs.forEach(para => {
                data.push({
                    type: "paragraph",
                    content: para,
                })
            })
            return data
        },
        // 上传文章
        upload() {
            this.$http.post(api, {
                title: this.title,
                overview: this.overview,
                coverImg: this.coverImg,
                tags: this.tags,
                paragraphs: this.generateUploadPara(),
            }).then(res=>{
                console.log(res)
                if (res.status == 200) {
                    Message({
                        type: "success",
                        message: "上传文章成功！",
                        duration: 1500,
                    })
                } else {
                    Message({
                        type: "error",
                        message: "上传文章失败！" + res.data.msg,
                        duration: 2500,
                    })
                }
            }).catch(err=>{
                console.warn(err)
            })
        },
    },
})
