

























































































import Vue from 'vue'
import {
    Button,
    Input,
    DatePicker,
    Icon,
    Message,
    // skeleton 和 skeleton-item 这两个必须要一起引用
    Skeleton,
    SkeletonItem,
    Popconfirm,
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(DatePicker)
Vue.use(Icon)
// skeleton 和 skeleton-item 这两个必须要一起引用
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Popconfirm)

let api = "diary/diary/"

export default Vue.extend({
    data() {
        return {
            date: '',
            content: '',
            createTime: '无',
            modifyTime: '无',

            statusList: [ "已就绪", "已修改 *", "无日记" ],
            status: "已就绪",

            originalContent: "", // 原始日记内容

            isDownloading: false, // 正在下载数据
            isUploading: false, // 正在上传数据
        }
    },
    methods: {
        lastDay() {
            if (this.isDownloading || this.isUploading) {
                return
            }
            let date = new Date(this.date)
            date.setDate(date.getDate() - 1)
            this.date = date.toString()
        },
        nextDay() {
            if (this.isDownloading || this.isUploading) {
                return
            }
            let date = new Date(this.date)
            date.setDate(date.getDate() + 1)
            this.date = date.toString()
        },
        // 根据日期请求日记内容
        getDiary() {
            this.isDownloading = true
            let date = new Date(this.date)
            let dateStr = String(date.getFullYear()) + "-" + String(date.getMonth()+1) + "-" + String(date.getDate())
            this.$http.get(api, {
                params: {
                    date: dateStr,
                },
            }).then(res=>{
                this.isDownloading = false
                console.log(res)
                if (res.status == 200) {
                    let data = res.data
                    this.originalContent = data.content
                    this.createTime = data.createTime
                    this.modifyTime = data.modifyTime
                } else {
                    Message({
                        showClose: true,
                        message: "这天没有日记呢！",
                        duration: 800,
                    })
                    this.status = this.statusList[2]
                    this.createTime = "无"
                    this.modifyTime = "无"
                }
            }).catch(err=>{
                this.isDownloading = false
                console.warn(err)
            })
        },
        // 保存日记
        save() {
            this.isUploading = true
            let date = new Date(this.date)
            this.$http({
                url: api,
                method: this.originalContent==""?"post":"put",
                data: {
                    date: date.toLocaleDateString().replaceAll('/', '-'),
                    content: this.content,
                }
            }).then(res=>{
                this.isUploading = false
                console.log(res)

                let success:boolean
                if (res.status == 200) {
                    success = true
                    this.originalContent = this.content
                    this.judgeStatus()
                } else {
                    success = false
                }

                Message({
                    showClose: true,
                    message: success?"保存成功":"保存失败",
                    duration: 2000,
                    type: success?'success':'error',
                })
                
                if (success) {
                    this.getDiary()
                }
            }).catch(err=>{
                this.isUploading = false
                console.warn(err)
            })
        },
        // 取消编辑，设置内容为原日记
        cancle() {
            this.content = this.originalContent
        },
        // 删除日记
        deleteDiary() {
            let date = new Date(this.date)
            this.$http({
                url: api,
                method: 'delete',
                data: {
                    date: date.toLocaleDateString().replaceAll('/', '-'),
                    content: this.content,
                }
            }).then(res=>{
                this.isUploading = false
                console.log(res)

                let success:boolean
                if (res.status == 200) {
                    success = true
                    this.originalContent = ""
                } else {
                    success = false
                }

                Message({
                    showClose: true,
                    message: success?"删除成功":"删除失败",
                    duration: 2000,
                    type: success?'success':'error',
                })
            }).catch(err=>{
                this.isUploading = false
                console.warn(err)
            })
        },
        // 比较输入与原内容，改变状态显示
        judgeStatus() {
            if (this.content == this.originalContent) {
                this.status = this.statusList[0]
            } else {
                this.status = this.statusList[1]
            }
        },
        // Ctrl+s 保存功能
        handleCtrlS(event) {
            if (event.key == "s" && event.ctrlKey) {
                event.preventDefault()
                console.log("Ctrl+S")
                if (this.status != this.statusList[1]) {
                    console.log("no change")
                    return
                }
                this.save()
            }
        },
    },
    watch: {
        originalContent(newVal, oldVal) {
            this.content = this.originalContent
        },
        content(newVal, oldVal) {
            this.judgeStatus()
        },
        date(newVal, oldVal) {
            this.originalContent = ""
            this.getDiary()
        },
    },
    beforeMount() {
        this.date = new Date().toString()

        document.addEventListener("keydown", this.handleCtrlS)
    },
    mounted() {
        (<Input>this.$refs.input).focus()
        this.getDiary()
        this.content = this.originalContent
        this.judgeStatus()
    },
    destroyed() {
        document.removeEventListener("keydown", this.handleCtrlS)
    },
})
