










































import Vue from 'vue'

interface NewestParagraph {
    id: number,
    title: string,
    profile: string,
    date: string,
}

export default Vue.extend({
    data() {
        return {
            newestPara: [] as NewestParagraph[],
        }
    },
    methods: {
        newestParaClick(id:number) {
            console.log(id)
            this.$router.push({
                path: "/blog-details",
                query: {
                    id: id.toString(),
                }
            })
        },
        getNewestParagraph() {
            this.$http.get("/blog/newest/")
            .then(res=>{
                console.log(res)
                if (res.status == 200) {
                    let data = res.data
                    console.log(data)
                    data.forEach(para => {
                        let profile
                        if (para.content.length > 34) {
                            profile = para.content.slice(0, 34) + "......"
                        } else {
                            profile = para.content
                        }
                        this.newestPara.push({
                            id: para.id,
                            title: para.title,
                            profile: profile,
                            date: para.modifyTime,
                        })
                    });
                } else {

                }
            }).catch(err=>{
                console.warn(err)
            })
        },
    },
    beforeMount() {
        this.getNewestParagraph()
    },
})
