import VueRouter from 'vue-router'

import Index from '@/components/index.vue'
import Blog from '@/components/blog.vue'
import About from '@/components/about.vue'
import Diary from '@/components/diary.vue'
import BlogDetails from '@/components/blog-details.vue'
import BlogAdd from '@/components/blog-add.vue'

const router = new VueRouter({
    routes: [
        { path: '/', redirect: "/index" },
        { path: '/index', component: Index },
        { path: '/blog', component: Blog },
        { path: '/about', component:About },
        { path: '/diary', component: Diary },
        { path: '/blog-details', component: BlogDetails },
        { path: '/blog-add', component: BlogAdd },
    ],
    mode: "history",
})

export default router