import Axios from "axios"

export default function(Vue) {
    let obj = Axios.create({
        baseURL: process.env.VUE_APP_BaseURL,
        validateStatus: status => {
            return true
        },
    })

    Vue.prototype.$http = obj
}