

























































import Vue from 'vue'

interface contentItem {
    id: number,
    title: string,
    content: string,
    imgUrl: string,
    tags: string[],
}

export default Vue.extend({
    data() {
        return {
            content: [] as contentItem[],
        }
    },
    methods: {
        readmore(id:number) {
            console.log(id)
            this.$router.push({
                path: "/blog-details",
                query: {
                    id: id.toString(),
                }
            })
        },
    },
    beforeMount() {
        this.$http.get("blog/index/")
        .then(res=>{
            console.log(res)
            if (res.status == 200)
            {
                let data = res.data
                console.log(data)
                this.content = data
            } else {
                this.$message.info({
                    message: res.data.msg,
                    duration: 2000,
                })
            }
        }).catch(err=>{
            console.warn(err)
            this.$message.error({
                message: "获取不到最新文章，刷新试试？",
                duration: 2000,
            })
        })
    },
})
