import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)
import router from './router'

import faker from '@/utils/faker'
Vue.use(faker)

import axios from '@/utils/axios'
Vue.use(axios)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
