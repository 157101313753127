

















import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            menuList: [
                { name: "主页", to: "index" },
                { name: "博客", to: "blog" },
                { name: "关于我", to: "about" },
            ],
            selectedMenuIndex: 0,
        }
    },
    methods: {
        handleNav(index:number) {
            this.selectedMenuIndex = index
            let to = this.menuList[index].to
            this.$router.push({ path: to })
        },
    },
    beforeMount() {
        //this.handleNav(0)
    },
})
