






import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            beian: "",
        }
    },
    mounted() {
        this.beian = process.env.VUE_APP_beian
    }
});
