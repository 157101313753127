










import Vue from 'vue';
import MyHeader from './components/header.vue'
import MyFooter from './components/footer.vue'

export default Vue.extend({
  name: 'App',
  components: {
    MyFooter,
    MyHeader,
  }
});
